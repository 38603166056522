
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AuthorTable from "@/components/document/AuthorTable.vue";
import RecordTable from "@/components/document/RecordTable.vue";
import { Ref } from 'vue';

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AuthorTable,
    RecordTable
  },
  setup() {
    const model = CloudFun.current?.model;
    const enums = model?.enums as any;
    const grid = ref<any>({});
    const documentStatus = ref();
    const isDisabled = ref(false);

    const gridOptions: GridOptions = {
      stripe: false,
      title: '研究任務',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      columns: [{
        field: 'IndustryCategoryName',
        title: '產業類別',
        width: '10%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: false,
      },
      {
        field: 'Title',
        title: '標題',
        width: '30%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      {
        field: 'ReportUser.Name',
        title: '撰寫人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ProofUser.Name',
        title: '校稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'ReviewUser.Name',
        title: '審稿人',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
      },
      {
        field: 'PublishedDate',
        title: '出版截止日期',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item: any = model?.enums && Object.values(enums.DocumentStatus).find((e: any) => e.Value === cellValue);
          return item ? item.Name : "";
        }
      },
      // {
      //   field: 'TopicType',
      //   title: '主題',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.TopicType).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      {
        title: '標題變更',
        width: '100',
        slots: { default: "edit-title" }
      },
      ],
      promises: {
        query: model ? (params) => {
          let condition = new Condition("Title", Operator.IsNotNull).and("Status", Operator.NotEqual, enums.DocumentStatus.Revoked.Value);
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          documentStatus.value = (model?.enums as any).DocumentStatus.Changing.Value;
          if (documentStatus.value != null) {
            // // 此三種狀態顯示皆為撰稿中，故要一起查詢
            // if (documentStatus.value === enums.DocumentStatus.Writing.Value) {
            //   const value = [enums.DocumentStatus.Writing.Value, enums.DocumentStatus.ChangedReturn.Value, enums.DocumentStatus.Changed.Value];
            //   condition = condition.and("Status", Operator.In, value).and(params.condition!);
            // } else {
            condition = condition.and("Status", Operator.Equal, documentStatus.value).and(params.condition!);
            // }
          }
          params.condition = condition;
          return model.dispatch('document/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model ? (params) => model.dispatch('document/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "80%", showFooter: true },
      treeConfig: { children: 'Children' }
    }

    // const newRowForm = ref(null);
    // const newRowModal = reactive({
    //   visible: false,
    //   formData: {
    //     TopicType: 0
    //   },
    //   submit() {
    //     if (!model) return;
    //     model.dispatch('document/insert', newRowModal.formData)
    //       .then(() => {
    //         newRowModal.formData = {
    //           TopicType: 0
    //         };
    //         grid.value.refresh();
    //         newRowModal.visible = false;
    //       }, (reason) => {
    //         CloudFun.send("error", {
    //           subject: "保存失敗",
    //           content: reason,
    //         });
    //       });
    //   },
    //   reset() {
    //     newRowModal.formData = {
    //       TopicType: 0
    //     };
    //   }
    // })

    const selectedTopicType = ref<number>(0);
    const newRowProgramSelect = ref<any>();
    const editRowProgramSelect = ref<any>();
    const disableProgramSelect = ref(false);
    const onGridAdd = (row: any, callback: any) => {
      const enableItems = formOptions.items || [];
      enableItems.forEach(e => {
        e.itemRender = e.itemRender || {} as any;
        e.itemRender!.props = e.itemRender!.props || {};
        e.itemRender!.props.disabled = false;
      });
      row.HideReportUser = false;
      row.Status = 1;
      row.ProofPayStatus = 1;
      row.ReviewPayStatus = 1;
      fileModal.setRow(row);
      callback();
    }
    const onGridEdit = async (row: any, callback: any) => {
      if (!row.Id) { callback(); return; }
      const entity = await model?.dispatch('document/find', row.Id);
      onTopicTypeChange(editRowProgramSelect, row);
      Object.assign(row, entity);
      fileModal.setRow(row);

      if (row.Status === (model?.enums as any).DocumentStatus.Revoked.Value) {
        const disableItems = formOptions.items || [];
        disableItems.forEach(e => {
          e.itemRender = e.itemRender || {} as any;
          e.itemRender!.props = e.itemRender!.props || {};
          e.itemRender!.props.disabled = true;
        });
      } else if (row.Status === (model?.enums as any).DocumentStatus.Approved.Value) {
        const enableItems = formOptions.items?.filter(e => e.field && ["ProofFee", "ProofPayStatus", "ReviewFee", "ReviewPayStatus"].indexOf(e.field) > -1) || [];
        enableItems.forEach(e => {
          e.itemRender = e.itemRender || {} as any;
          e.itemRender!.props = e.itemRender!.props || {};
          e.itemRender!.props.disabled = false;
        });

        const disableItems = formOptions.items?.filter(e => e.field && ["ProofFee", "ProofPayStatus", "ReviewFee", "ReviewPayStatus"].indexOf(e.field) === -1) || [];
        disableItems.forEach(e => {
          e.itemRender = e.itemRender || {} as any;
          e.itemRender!.props = e.itemRender!.props || {};
          e.itemRender!.props.disabled = true;
        });
      } else {
        // const disableItems = formOptions.items?.filter(e => e.field && ["Title", "PublishedDate", "ReportUserId"].indexOf(e.field) > -1) || [];
        // disableItems.forEach(e => {
        //   e.itemRender = e.itemRender || {} as any;
        //   e.itemRender!.props = e.itemRender!.props || {};
        //   e.itemRender!.props.disabled = true;
        // });
        // const enableItems = formOptions.items?.filter(e => e.field && ["Title", "PublishedDate", "ReportUserId"].indexOf(e.field) === -1) || [];
        // enableItems.forEach(e => {
        //   e.itemRender = e.itemRender || {} as any;
        //   e.itemRender!.props = e.itemRender!.props || {};
        //   e.itemRender!.props.disabled = false;
        // });
        const enableItems = formOptions.items || [];
        enableItems.forEach(e => {
          e.itemRender = e.itemRender || {} as any;
          e.itemRender!.props = e.itemRender!.props || {};
          e.itemRender!.props.disabled = false;
        });
      }
      callback();
    };
    const onTopicTypeChange = async (instance: Ref<any>, data: any) => {
      selectedTopicType.value = data.TopicType;
      data.ProgramIds = [];
      disableProgramSelect.value = (await model!.dispatch("program/getChildOnly", { mode: "count", type: selectedTopicType.value })) < 1;
      if (instance.value && instance.value.grid.refresh) {
        instance.value.grid.refresh();
      }
    };
    const newRowFormOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(newRowProgramSelect, e.data) } }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        // {
        //   field: 'ContentType',
        //   title: '內容類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // {
        //   field: 'SourceType',
        //   title: '來源類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          title: '產業類別',
          span: 24,
          slots: { default: "column-programs" }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" }
        },
        {
          field: 'ReportDate',
          title: '撰稿截止日期',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          span: 12,
        },
        {
          field: 'ProofUserId',
          title: '校稿人',
          span: 12,
          slots: { default: "column-proof-user-id" }
        },
        {
          field: 'ProofDate',
          title: '校稿截止日期',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'ReviewUserId',
          title: '審稿人',
          span: 12,
          slots: { default: "column-review-user-id" }
        },
        {
          field: 'ReviewDate',
          title: '審稿截止日期',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        ContentType: [{ required: true }],
        SourceType: [{ required: true }],
        // ProgramIds: [{ required: true }],
        ReportDate: [{ required: true }],
        ReportUserId: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
      }
    }

    const showEditSlot = (row: any) => {
      if (row.data.Id) return true;
      return false;
    }

    const form = ref<any>();
    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入標題' } }
        },
        {
          field: 'PublishedDate',
          title: '出版日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'TopicType',
          title: '主題',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], events: { change: (e: any) => onTopicTypeChange(editRowProgramSelect, e.data) } }
        },
        {
          field: 'ProgramIds',
          title: '產業類別',
          span: 12,
          slots: { default: "column-programs" }
        },
        {
          field: 'ContentType',
          title: '內容類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.ContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SourceType',
          title: '來源類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.SourceType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReportUserId',
          title: '負責人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        {
          field: 'HideReportUser',
          title: '出版時不顯示負責人於作者欄',
          span: 12,
          titleWidth: 230,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Keywords',
          title: '關鍵字',
          span: 24,
          slots: { default: "column-keywords" },
          visibleMethod: showEditSlot
        },
        {
          span: 24,
          // titleWidth: 80,
          slots: { default: "column-authors-list" }
        },
        {
          field: 'ReportDate',
          title: '撰稿截止日期',
          span: 24,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'ProofUserId',
          title: '校稿人',
          span: 12,
          slots: { default: "column-proof-user-id" }
        },
        {
          field: 'ProofDate',
          title: '校稿截止日期',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        // {
        //   field: 'ProofFee',
        //   title: '校稿費',
        //   span: 12,
        //   titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        // },
        {
          field: 'ReviewUserId',
          title: '審稿人',
          span: 12,
          slots: { default: "column-review-user-id" }
        },
        {
          field: 'ReviewDate',
          title: '審稿截止日期',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        // {
        //   field: 'ReviewFee',
        //   title: '審稿費',
        //   span: 12,
        //   titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        // },
        {
          field: 'ProofPayStatus',
          title: '校稿費付款狀態',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.PayStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ReviewPayStatus',
          title: '審稿費付款狀態',
          span: 12,
          // titleWidth: 120,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.PayStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: 'IgnoreReroof',
        //   title: '重新送審不需校稿',
        //   span: 12,
        //   titleWidth: 150,
        //   itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }], props: { disabled: true } }
        // },
        {
          field: 'Page',
          title: '頁數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          // titleWidth: 80,
          itemRender: { name: '$textarea' }
        },
        {
          span: 24,
          slots: { default: "column-document-records" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        PublishedDate: [{ required: true }],
        TopicType: [{ required: true }],
        HideReportUser: [{ required: true }],
        ReportUserId: [{ required: true }],
        ReportDate: [{ required: true }],
        ProofUserId: [{ required: true }],
        ProofDate: [{ required: true }],
        ReviewUserId: [{ required: true }],
        ReviewDate: [{ required: true }],
        SourceType: [{ required: true }],
        ContentType: [{ required: true }],
        programs: [{ required: true }],
        ProgramIds: [
          { required: true },
          {
            validator({ itemValue }) {
              if (!disableProgramSelect.value && (!itemValue || itemValue.length === 0)) {
                return new Error("請選擇至少一項產業類別");
              }
            }
          }
        ],
      }
    }

    const programSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          width: "40%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }, {
          field: "FullName",
          title: "全名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("program/find", value), // eslint-disable-line
        query: params => model!.dispatch("program/getChildOnly", { ...params, type: selectedTopicType.value }) // eslint-disable-line
      }
    };

    const keywordSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇關鍵字",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          return model!.dispatch("keyword/query", params) // eslint-disable-line
        },
        insert: (value) => {
          var data = { entity: { Name: value.Name, Published: true } };
          return model!.dispatch("keyword/insert", data)
        },
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    // 只在新增時需要
    const onReportUserChange = async (row: any, id: number) => {
      if (row.Id) return;
      if (!id) {
        row.ProofUserId = null;
        row.ReviewUserId = null;
        return;
      }
      const user = await model?.dispatch("user/find", id);
      row.ProofUserId = user.ProofUser?.Status === 10 ? user?.ProofUserId : null;
      row.ReviewUserId = user.ReviewUser?.Status === 10 ? user?.ReviewUserId : null;
    }

    const authorTable = ref<any>()

    const editTitleForm = ref<any>();
    const editTitleModal = reactive({
      visible: false,
      selectedRow: {} as any,
      formData: {
        Id: "",
        OldTitle: "",
        Title: "",
        TitleReason: "",
        OldPublishedDate: "",
        PublishedDate: "",
        PublishedDateReason: "",
        OldReportUser: "",
        ReportUser: "",
        ReportUserReason: "",
        Comment: "",
        Status: 0,
        RecordId: "",
        FileIds: [],
        Files: [] as { Id: string; Name: string }[]
      },
      formOptions: {
        titleWidth: 80,
        titleAlign: 'right',
        items: [
          {
            field: 'OldTitle',
            title: '原標題',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', disabled: true } }
          },
          {
            field: 'Title',
            title: '新標題',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'TitleReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'OldPublishedDate',
            title: '原出版日期',
            span: 8,
            titleWidth: 100,
            itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: true } }
          },
          {
            field: 'PublishedDate',
            title: '新出版日期',
            span: 8,
            titleWidth: 100,
            itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
          },
          {
            field: 'PublishedDateReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            field: 'OldReportUser',
            title: '原撰稿人',
            span: 8,
            titleWidth: 80,
            slots: { default: "column-report-user-id" }
          },
          {
            field: 'ReportUser',
            title: '新撰稿人',
            span: 8,
            titleWidth: 80,
            slots: { default: "column-new-report-user-id" }
          },
          {
            field: 'ReportUserReason',
            title: '變更原因',
            span: 8,
            itemRender: { name: '$input', props: { placeholder: '請輸入原因', clearable: true }, attrs: { type: 'text' } }
          },
          {
            span: 24,
            slots: { default: "comment" }
          },
          {
            span: 24,
            slots: { default: "files" }
          },
        ]
      } as VxeFormProps,
      // uploadOptions: {
      //   canUpload: false,
      //   canDelete: false,
      //   promises: {
      //     query() {
      //       return model?.dispatch('files/query', {
      //         condition: new Condition("DocumentRecordId", Operator.Equal, editTitleModal.formData.RecordId)
      //           .and("Type", Operator.Equal, 4)
      //       });
      //     }
      //   }
      // },
      downloadFile(id: string) {
        window.open(`${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`);
      },
      async show(row: any) {
        editTitleModal.visible = true;
        editTitleModal.selectedRow = row;
        editTitleModal.formData = {
          Id: row.Id,
          OldTitle: row.Title,
          OldPublishedDate: row.PublishedDate,
          OldReportUser: row.ReportUserId,
          Status: row.Status
        } as any;
        if (editTitleModal.formData.Status === (model?.enums as any).DocumentStatus.Changing.Value) {
          var records = await model!.dispatch("documentRecord/query", { page: 1, pageSize: 1, condition: new Condition("DocumentId", Operator.Equal, editTitleModal.formData.Id), sortings: new Sorting("CreatedTime", SortOrder.Descending) });
          const r = records.data[0];
          editTitleModal.formData.RecordId = r.Id;
          editTitleModal.formData.Comment = r.Comment;
          editTitleModal.formData.Title = r.NewTitle;
          editTitleModal.formData.TitleReason = r.TitleReason;
          editTitleModal.formData.PublishedDate = r.NewPublishedDate;
          editTitleModal.formData.PublishedDateReason = r.PublishedDateReason;
          editTitleModal.formData.ReportUser = r.NewReportUserId;
          editTitleModal.formData.ReportUserReason = r.ReportUserReason;

          const files = await model?.dispatch('files/query', {
            condition: new Condition("DocumentRecordId", Operator.Equal, editTitleModal.formData.RecordId)
              .and("Type", Operator.Equal, 4)
          });
          editTitleModal.formData.Files = files;
        }
      },
      submit() {
        if (!editTitleModal.formData.Title && !editTitleModal.formData.PublishedDate && !editTitleModal.formData.ReportUser) return;

        model!.dispatch("document/editTitle", editTitleModal.formData).then(() => {
          editTitleModal.visible = false;
          grid.value.refresh();
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      },
      approve() {
        model!.dispatch("document/approveChange", editTitleModal.formData).then(() => {
          editTitleModal.visible = false;
          grid.value.refresh();
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      },
      reject() {
        model!.dispatch("document/rejectChange", editTitleModal.formData).then(() => {
          editTitleModal.visible = false;
          grid.value.refresh();
        }, failure => CloudFun.send('error', { subject: '操作失敗！', content: failure }))
      }
    })

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      filesWhenCreate: [] as any[],
      setRow(row: any) {
        fileModal.selectedRow = row;
        fileModal.filesWhenCreate = [];
      },
      uploadOptions: {
        title: "上傳研究報告",
        promises: {
          query() {
            return fileModal.selectedRow.Id ? model?.dispatch('files/query', {
              condition: new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id)
                .and("Type", Operator.Equal, 5)
            }) : fileModal.filesWhenCreate;
          },
          insert(formData: FormData) {
            return model ? new Promise(resolve => {
              model.dispatch('files/insert', {
                formData,
                mode: 'other',
                entityId: fileModal.selectedRow.Id ? 'Document_' + fileModal.selectedRow.Id : '',
                fileType: 5
              }).then(res => {
                if (res && res.length > 0) {
                  fileModal.filesWhenCreate = fileModal.filesWhenCreate.concat(res);
                }
                resolve(undefined);
              });
            }) : undefined
          },
          delete(id: number) {
            return model ? new Promise(() => {
              model.dispatch('files/delete', id).then(() => {
                const idx = fileModal.filesWhenCreate.findIndex(e => e.Id === id);
                if (idx > -1) fileModal.filesWhenCreate.splice(idx, 1);
              });
            }) : undefined
          }
        }
      },
      show(row: any) {
        fileModal.visible = true;
        fileModal.setRow(row);
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    return {
      grid,
      documentStatus,
      gridOptions,
      newRowProgramSelect,
      editRowProgramSelect,
      disableProgramSelect,
      onGridAdd,
      onGridEdit,
      // newRowForm,
      // newRowModal,
      newRowFormOptions,
      form,
      formOptions,
      onReportUserChange,
      model,
      programSelectorOptions,
      keywordSelectorOptions,
      userSelectorOptions,
      authorTable,
      editTitleForm,
      editTitleModal,
      fileGrid,
      fileModal,
      isDisabled
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    hasApproved(status: number) {
      const documentStatus = this.$model.enums.DocumentStatus as any;
      return status === documentStatus.Approved.Value || status === documentStatus.Revoked.Value;
    }
  }
})
